import AboutMeSlideShow from '../components/AboutMeSlideShow'

const AboutMe = () => {
    return(
        <div id="about">
            <AboutMeSlideShow />         
        </div>
    )
}

export default AboutMe